<template>
  <section class="faq">
    <div class="row">
      <div class="col-lg-12">
        <div v-if="this.$i18n.locale === 'ru'" class="card">
          <div class="card-body">
            <div class="faq-section">
              <div class="container-fluid bg-gradient-info py-2">
                <p class="mb-0 text-white">Как работает DeFi AMMob?</p>
              </div>
              <div>
                <b-card no-body>
                  <b-card-body>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <p>Платформа XBTS DeFi AMMOB - это DeFi протокол с Автоматизированным Маркет-Мейкером Книги
                          Ордеров, предназначенный для мгновенного обмена между криптовалютами с низкими комиссиями и
                          ограниченным проскальзыванием.
                          Протоколы ликвидности платформы XBTS DeFi AMMOB позволяют любому стать Маркет-Мейкером,
                          добавлять свои активы в несколько различных пулов ликвидности и зарабатывать комиссии от всех
                          операций в пулах.</p>
                        <p>Поставщики ликвидности, которые объединяют свой капитал в платформу XBTS DeFi, создают рынки,
                          всегда торгуя по ценам, установленным алгоритмом ценообразования AMMOB, выступая в качестве
                          Информированных Маркет-Мейкеров.</p>
                        <p>Скорость подтверждения операции в блокчейне BitShares менее 3 секунд. Лимитные ордера всегда
                          сопоставляются с пулами ликвидности, что обеспечивает лучшую рыночную цену!</p>
                        <p>
                          <a target="_blank" href="https://hive.blog/bitshares/@xbts/defi-ammob-defi-with-automated-market-maker-order-book">Узнать
                            подробнее о технологии DeFi AMMob</a>
                        </p>

                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </div>
            </div>

            <div class="faq-section mt-4">
              <div class="container-fluid bg-gradient-info py-2">
                <p class="mb-0 text-white">Регистрация и Импорт Аккаунта</p>
              </div>
              <div>
                <b-card no-body>
                  <b-card-body>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <p>Регистрация на платформе DeFi AMMOB https://app.xbts.io предоставит Вам полный доступ к
                          пулам ликвидности, стейкинг программе и торговле в блокчейне. Вы сможете начать торговать,
                          работать с пулами ликвидности, стейкать и свапать активы в несколько кликов.</p>
                        <p>Биржа XBTS работает на блокчейне BitShares, поэтому Вы можете импортировать свою учетную
                          запись <a href="https://xbts.io">XBTS</a> или <a href="https://wallet.bitshares.org">BitShares</a>. Информацию о своей учетной записи Вы также можете
                          просмотреть в обозревателе блоков блокчейна BitShares <a href="https://bts.ai">https://bts.ai</a></p>
                        <p>Кошелек XBTS DeFi Wallet никогда не хранит и не имеет доступа к вашим средствам - Вы
                          полностью контролируете свои личные ключи. Только у вас есть доступ к вашей учетной
                          записи.</p>
                        <p>Если вы потеряете парольную фразу, ее невозможно будет восстановить. Сохраните парольную
                          фразу в надежном месте!</p>
                        <p>Кошелек XBTS DeFi - это прогрессивный кроссплатформенный веб/онлайн/настольный кошелек XBTS
                          DeFi, предоставляющий возможности хранения, отправки и получения криптовалют в нативные
                          блокчейны и Cross-Chain транзакции.</p>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </div>
            </div>

            <div class="faq-section mt-4">
              <div class="container-fluid bg-gradient-info py-2">
                <p class="mb-0 text-white">XBTS Кошелёк</p>
              </div>
              <div>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Пин-код</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      Мы создали умную систему ПИН-кода, которая предотвращает любые формы перехвата данных или
                      кейлоггеров. Пин-код встроен в сам кошелек XBTS DeFi с возможностью индивидуального выбора
                      Пин-кода для максимального уровня защиты кошелька. Пин-код преобразуется в хэш SHA-384 и шифруется
                      с использованием алгоритмов AES. Пин-код хранится на вашем устройстве локально и никогда не
                      передается в сеть.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Cross-Chain Кошелёк</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      В разделе кошелька отображаются все активы, которые есть на вашем балансе. В закладке DeFi
                      отображаются все Ваши Пулы Ликвидности и LP токены, которые есть на вашем балансе.
                    </p>
                    <p>
                      Вы можете выбрать валюту, в которой будет отображаться сумма ваших активов.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Депозит и вывод</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      Вы можете внести депозит или вывести любой актив, нажав «Deposit» или «Withdraw».
                    </p>
                    <p>
                      Биржа XBTS поддерживает пополнение и вывод средств на собственные блокчейны криптовалют: Bitcoin,
                      Ethereum, Litecoin, Dogecoin, SmartHoldem, Waves и другие, а также прямые Cross-Chain операции по
                      пополнению и выводу средств с другими биржами криптовалют: Binance Chain BEP2, Huobi Eco Chain
                      HECO, блокчейн Waves, блокчейн Ethereum.
                    </p>
                    <p>
                      Просто выберите блокчейн, через который вы хотите сделать депозит или вывод средств.
                    </p>
                    <p>У разных blockchain - разные комиссии.</p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Депозит/Вывод BitShares BTS</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      BitShares BTS Депозит и вывод будет отличаться от других криптовалют, поскольку BTS является core
                      активом блокчейна BitShares.
                    </p>
                    <p>
                      Чтобы сделать депозит BTS на XBTS DEX в блокчейне BitShares, используйте ИМЯ своей учетной записи.
                    </p>
                    <p>
                      ИМЯ ВАШЕЙ УЧЕТНОЙ ЗАПИСИ ЯВЛЯЕТСЯ АДРЕСОМ ВАШЕЙ УЧЕТНОЙ ЗАПИСИ В БЛОКЧЕЙНЕ BITSHARES. Вы можете
                      использовать одну учетную запись во всех торговых приложениях XBTS, поскольку все приложения XBTS
                      работают в блокчейне BitShares.
                    </p>
                    <p>
                      Чтобы вывести BTS на Binance или другую цепочку, используйте XBTS Dex Trading Interface
                      https://ex.xbts.io
                    </p>
                    <div>
                      <ul>
                        <li>
                          В правом верхнем углу интерфейса XBTS Dex нажмите «ОТПРАВИТЬ».
                        </li>
                        <li>
                          Скопируйте именной адрес на Binance и вставьте его в форму XBTS.
                        </li>
                        <li>
                          Укажите получателя (например при выводе BTS на Binance, адрес <a target="_blank" href="https://ex.xbts.io/account/binance-bts-1">binance-bts-1</a>)
                        </li>
                        <li>
                          Укажите сумму
                        </li>
                        <li>
                          Внимательно укажите свой Binance MEMO! <span class="text-danger">MEMO указывать в большинстве случаев Обязательно!</span>
                        </li>
                        <li>
                          Кликните Отправить
                        </li>
                      </ul>
                    </div>
                  </b-card-body>
                </b-card>
              </div>
            </div>

            <div class="faq-section mt-4">
              <div class="container-fluid bg-gradient-info py-2">
                <p class="mb-0 text-white">DeFi AMMob Пулы - пошаговое руководство</p>
              </div>
              <div>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">DeFi AMMOB</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      Список Пулов Ликвидности отображается на странице DeFi AMMOB. Список пулов, представленный в этом
                      разделе, работает по протоколу DeFi с Автоматизированным Маркет-Мейкером Книги Ордеров.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Выберите пул</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      Вы можете выбрать любой пул.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">«Стейк» -Добавление Ликвидности</span>
                  </b-card-header>
                  <b-card-body>
                    <ul>
                      <li>Вы можете указать любую сумму, которую хотите добавить в пул. Система автоматически рассчитает
                        сколько необходимо токенов A и токенов B. При финансировании пула от поставщиков ликвидности
                        требуется финансировать два разных актива, чтобы трейдеры могли переключаться между одним и
                        другим, торгуя в паре активов.
                      </li>
                      <li>Вам нужно ввести только одно значение, второе будет автоматически рассчитано на основе
                        текущего коэффициента пула. Вы можете заранее рассчитать, сколько вам нужно внести, чтобы
                        получить желаемый процент.
                      </li>
                      <li>
                        Чтобы стать поставщиком ликвидности и начать участие в пуле - нажмите «Добавить в пул».
                      </li>
                      <li>В приложении вы можете увидеть последние операции, которые происходили в пуле. Каждая
                        swap-транзакция или добавление / снятие ликвидности записывается в блокчейн BitShares и может
                        быть проверена в Обозревателе Блоков в любой момент.
                      </li>
                    </ul>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">«Мои токены»</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      Здесь отображается ваша доля в различных пулах ликвидности и количество LP токенов в каждом пуле.
                    </p>
                    <ul>
                      <li>LP токены - Токены поставщика ликвидности.</li>
                      <li>Когда вы размещаете ликвидность в пуле, то получаете взамен специальные токены LP (название
                        происходит от Liquidity Provider) как эквивалент сумме внесенных средств. LP токены обозначают
                        право на долю криптовалют в пуле, и поставщик ликвидности в любой момент можете использовать LP
                        токены пула для возврата своей доли и дохода с комиссии.
                      </li>
                      <li>Все комиссии, заработанные в пуле, добавляются к ликвидности пула и распределяются между
                        поставщиками ликвидности - держателями LP токенов пропорционально % в пуле.
                      </li>
                      <li>Размер вознаграждений поставщикам ликвидности зависит от процента предоставляемых ими средств
                        в пул ликвидности.
                      </li>
                    </ul>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">«Обмен»</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      В Разделе «Обмен» вы можете купить, продать, обменять выбранный актив.
                      Это быстрый способ обмена криптовалюты по рыночной цене.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">APY</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      APY Annual Percentage Yield или Годовая процентная доходность – это предполагаемая годовая
                      процентная ставка. Изменяется в зависимости от количества операций за 24 часа
                    </p>
                  </b-card-body>
                </b-card>

                <div class="mt-4 text-white">
                  <p>Официальная группа XBTS Cross-Chain Dex & DeFi в Телеграм <a href="https://t.me/xbtsio">https://t.me/xbtsio</a>
                  </p>
                  <p><a target="_blank" href="https://xbts.io/terms">Условия использования XBTS DEX</a></p>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div v-else class="card">
          <div class="card-body">
            <div class="faq-section">
              <div class="container-fluid bg-gradient-info py-2">
                <p class="mb-0 text-white">How does DeFi AMMob work?</p>
              </div>
              <div>
                <b-card no-body>
                  <b-card-body>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <p>XBTS DeFi AMMob platform https://app.xbts.io is an Automated Market Maker Order Book protocol
                          designed for swapping between cryptocurrencies with low fees and limited slippage.</p>
                        <p>Liquidity protocols of XBTS DeFi AMMob platform allow anyone to become a Market
                          Maker, Add their assets to several different liquidity pools and Earn fees on many different
                          market pairs.</p>
                        <p>Price quotes on an DeFi are created through complex and scalible pricing algorithm
                          AMMob which does take into account any external information regarding of the world average
                          price of asset and trading spread on the BitShares blockchain.</p>
                        <p>Liquidity providers that pool their capital in an XBTS Defi AMMob Platform make markets by
                          always trading against the prices set by the AMMob's pricing algorithm, essentially acting
                          as Informed Market Makers.</p>
                        <p>Description of DeFi
                          AMMob <a
                              href="https://hive.blog/bitshares/@xbts/defi-ammob-defi-with-automated-market-maker-order-book">Read
                            more</a></p>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </div>
            </div>

            <div class="faq-section mt-4">
              <div class="container-fluid bg-primary py-2">
                <p class="mb-0 text-white">Register or import account </p>
              </div>
              <div>
                <b-card no-body>
                  <b-card-body>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <p>Open XBTS DeFi Wallet APP and register or import your account.</p>
                        <p>Just visit <a target="_blank" href="https://app.xbts.io">app.xbts.io</a> and start trading,
                          investing into liquidity pools and staking
                          assets in a few clicks.</p>
                        <p>The exchange runs on the BitShares blockchain, so you can import your XBTS or BitShares
                          account. Only you have access to your account. The APP does not store your data. If you lose
                          your passphrase, it cannot be recovered. Save the passphrase in a safe place.</p>
                        <p>XBTS DeFi Wallet is a Progressive Cross-Platform Web/Online/Desktop APP and
                          Cross-Chain Bridges.</p>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </div>
            </div>

            <div class="faq-section mt-4">
              <div class="container-fluid bg-gradient-info py-2">
                <p class="mb-0 text-white">XBTS Wallet</p>
              </div>
              <div>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Pin-code</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      We built the Pin-code system that stops any form of data sniffing or keyloggers. The Pin-code is
                      built into the XBTS DeFi wallet itself with ability to custom selected Pin-code for the ultimate
                      level of wallet protection. The Pin-code is converted to a SHA-384 hash and encrypts itself using
                      AES algorithms.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Cross-Chain Wallet</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      The wallet section displays all the assets that are on your balance. The Defi section displays
                      your stakes in DeFi pools. You can select the currency in which the amount of your assets will be
                      displayed.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Deposit & Withdraw</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      You can make a Deposit or Withdrawal of any asset by clicking Deposit or Withdraw.
                    </p>
                    <p>
                      The XBTS exchange supports the Deposit and Withdrawal on native blockchains of coins as well as
                      Cross-Chain bridges with other blockchains: Binance Chain BEP2, Huobi Eco Chain HECO, Waves
                      blockchain, Ethereum blockchain.
                      Just select the blockchain via which you want to make a Deposit or Withdrawal.
                    </p>
                    <p>
                      Different blockchains have different fees.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">BitShares (BTS) deposit/withdrawal</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      BitShares BTS Deposit and Withdrawal will differ from other cryptocurrencies, as BTS is the core
                      asset of the BitShares blockchain.
                    </p>
                    <p>
                      To make a BTS deposit to the XBTS DEX on the BitShares blockchain, use the NAME of your Account.
                    </p>
                    <p>
                      THE NAME OF YOUR ACCOUNT IS A YOUR ACCOUNT ADDRESS IN THE BITSHARES BLOCKCHAIN.
                    </p>
                    <p>
                      You can use one account in all XBTS trading applications, as all XBTS applications run on the
                      BitShares blockchain.
                    </p>
                    <p>
                      To make a BTS withdrawal to the Binance or other chain Use XBTS Dex Trading UI <a
                        href="https://ex.xbts.io">https://ex.xbts.io</a>

                    </p>
                    <div>
                      <ul>
                        <li>
                          In the top-right hand corner of the <a target="_blank" href="https://ex.xbts.io/">XBTS Dex
                          UI</a> click on "SEND"
                        </li>
                        <li>
                          Enter recipient into BitShares blockchain (ex. Verified Binance account <a
                            title="view Binance account into BitShares blockchain" target="_blank"
                            href="https://ex.xbts.io/account/binance-bts-1">binance-bts-1</a>)
                        </li>
                        <li>
                          Enter the amount
                        </li>
                        <li>
                          Enter your Binance MEMO! (ex. 123456789) <span class="text-danger">MEMO IS REQUIRED!</span>
                        </li>
                        <li>
                          Copy the Address on Binance and Paste it into the XBTS Form.
                        </li>
                        <li>
                          Click Send.
                        </li>

                      </ul>
                    </div>
                  </b-card-body>
                </b-card>
              </div>
            </div>

            <div class="faq-section mt-4">
              <div class="container-fluid bg-gradient-info py-2">
                <p class="mb-0 text-white">DeFi AMMob Pools - Step by Step Guide</p>
              </div>
              <div>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Go to the “DeFi AMMob” section</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      The list of pools is displayed on the Defi AMMOB page. The list of pools presented in this section
                      work according to the AMMOB protocol. The application displays a whitelist of pools.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Select a pool</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      You can select any Pool you want.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Stake</span>
                  </b-card-header>
                  <b-card-body>
                    <ul>
                      <li>You can specify the amount you want to add to the pool. The system automatically calculates
                        how many A and B tokens are needed
                      </li>
                      <li>You need to only enter one value, the second will be automatically calculated based on the
                        current pool ratio
                      </li>
                      <li>You can pre-calculate how much you need to deposit in order to get the desired percentage. To
                        start participating in the pool and becoming a liquidity provider
                      </li>
                      <li>Click "Add to Stake"</li>
                    </ul>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">"My Tokens" section</span>
                  </b-card-header>
                  <b-card-body>
                    <p>Your Stake in various liquidity pools and the amount of LP tokens in each pool are displayed
                      here.
                    </p>
                    <ul>
                      <li>Click to view the selected pool.</li>
                      <li>You can calculate your share and claim tokens at any time.</li>
                      <li>In the application, you can see the last operations that took place in the pool. Every swap
                        transaction or adding/withdraw liquidity, is recorded on the BitShares blockchain and can be
                        tracked in the Block Explorer.
                      </li>
                    </ul>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">Trade section</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      Here you can buy, sell, swap the selected asset.
                      It is a fast way to swap cryptocurrency at market price.
                    </p>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <span class="font-weight-bold text-white">APY</span>
                  </b-card-header>
                  <b-card-body>
                    <p>
                      Annual Percentage Yield (APY) - Estimated annual interest rate. Varies depending on the number of
                      transactions in 24 hours
                    </p>
                  </b-card-body>
                </b-card>

                <div class="mt-4 text-white">
                  <p>Official XBTS Cross-Chain Dex & DeFi Telegram <a href="https://t.me/xbtsio">https://t.me/xbtsio</a>
                  </p>
                  <p><a target="_blank" href="https://xbts.io/terms">XBTS DEX Terms of Use</a></p>
                </div>


              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "faq"
};
</script>